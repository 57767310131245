import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['province', 'district']

  submit(event) {
    const form = event.target.closest('form')
    form.submit()
  }

  railsSubmit(event) {
    event.preventDefault()

    const form = event.target.closest('form')
    form.addEventListener('ajax:beforeSend', event => {
      const [xhr, options] = event.detail
      window.filters_timestamp = new Date().getTime()
      xhr.setRequestHeader('timestamp', window.filters_timestamp)
      Turbolinks.controller.pushHistoryWithLocationAndRestorationIdentifier(options.url, Turbolinks.uuid())
    })
    Rails.fire(form, 'submit')
  }

  updateProvinces(event) {
    const region = this.element.querySelector('.js-region-select').value
    if (region) {
      fetch(`/airports/provinces?region=${region}`)
        .then(response => response.json())
        .then(data => {
          this.provinceTarget.innerHTML = '<option value="">Seleccionar provincia</option>'
          data.forEach(province => {
            const option = document.createElement('option')
            option.value = province
            option.textContent = province
            this.provinceTarget.appendChild(option)
          })
          this.provinceTarget.disabled = false
          this.railsSubmit(event)
        })
    } else {
      this.provinceTarget.innerHTML = '<option value="">Seleccionar provincia</option>'
      this.provinceTarget.disabled = true
      this.districtTarget.innerHTML = '<option value="">Seleccionar distrito</option>'
      this.districtTarget.disabled = true
      this.railsSubmit(event)
    }
  }

  updateDistricts(event) {
    const province = this.element.querySelector('.js-province-select').value
    if (province) {
      fetch(`/airports/districts?province=${province}`)
        .then(response => response.json())
        .then(data => {
          this.districtTarget.innerHTML = '<option value="">Seleccionar distrito</option>'
          data.forEach(district => {
            const option = document.createElement('option')
            option.value = district
            option.textContent = district
            this.districtTarget.appendChild(option)
          })
          this.districtTarget.disabled = false
          this.railsSubmit(event)
        })
    } else {
      this.districtTarget.innerHTML = '<option value="">Todos los distritos</option>'
      this.districtTarget.disabled = true
      this.railsSubmit(event)
    }
  }
}
